import {
  Tabs as BaseTabs,
  Tab as BaseTab,
  TabsProps,
  TabProps,
  SharedProps,
  TabsOverrides,
  TabOverrides
} from 'baseui/tabs'
import React from 'react'
import { useStyletron } from 'baseui'
import { StyleObject } from 'styletron-react'
import { ourColors } from './Colors'
import { withOverrides } from './overrides'

const globalTabsOverrides: TabsOverrides<SharedProps & { $active?: boolean }> = {
  Root: {
    style: {
      flexGrow: 1,
      width: '100%'
    }
  },
  TabContent: {
    style: {
      paddingTop: '36px',
      paddingRight: '0px',
      paddingLeft: '0px',
      backgroundColor: '#FFFFFF'
    }
  },
  TabBar: {
    style: {
      paddingRight: '0px',
      paddingLeft: '0px',
      marginTop: '6px',
      backgroundColor: '#FFFFFF',
      height: '70px'
    }
  }
}

const globalTabOverrides: TabOverrides<SharedProps> = {
  Tab: {
    style: ({ $theme, $active }) => {
      const style: StyleObject = {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: '0px',
        marginLeft: '0px',
        paddingRight: '6px',
        paddingLeft: '6px',
        fontFamily: 'Roboto, sans-serif',
        fontSize: '18px',
        fontWeight: 'normal'
      }
      if ($active) {
        style.borderBottom = `${$theme.sizing.scale0} solid rgb(255, 255, 255)`
        style.borderTop = `${$theme.sizing.scale0} solid ${ourColors.interfaceGrey}`
        style.borderLeft = `${$theme.sizing.scale0} solid ${ourColors.interfaceGrey}`
        style.borderRight = `${$theme.sizing.scale0} solid ${ourColors.interfaceGrey}`
      } else {
        style.backgroundColor = ourColors.lightGrey
        style.borderBottom = `${$theme.sizing.scale0} solid ${ourColors.interfaceGrey}`
      }
      return style
    }
  }
}

const OverriddenTabs = withOverrides<TabsProps>(BaseTabs, globalTabsOverrides)

export const Tabs: React.FunctionComponent<TabsProps> = (props: TabsProps) => {
  const [css, $theme] = useStyletron()
  const horizontalRuleLength = '15.5px'

  return (
    <div
      className={css({
        display: 'flex'
      })}
    >
      <div
        className={css({
          height: '76px',
          width: horizontalRuleLength,
          position: 'absolute',
          marginLeft: '-' + horizontalRuleLength,
          borderBottom: `${$theme.sizing.scale0} solid ${ourColors.interfaceGrey}`
        })}
      />
      <OverriddenTabs {...props} />
      <div
        className={css({
          height: '76px',
          width: horizontalRuleLength,
          marginRight: '-' + horizontalRuleLength,
          position: 'relative',
          borderBottom: `${$theme.sizing.scale0} solid ${ourColors.interfaceGrey}`
        })}
      />
    </div>
  )
}

export const Tab = withOverrides<TabProps>(BaseTab, globalTabOverrides)
